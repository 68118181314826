<template>

<!-- <spinner v-if="load" /> -->

  <b-card-code >
 <div>
  <good-table-basic />
  </div>
  </b-card-code>
 
</template>

<script>

import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BAvatar, BBadge,BButton,  BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import GoodTableBasic from '../components/DepositTable.vue'


export default {
  components: {
    GoodTableBasic,
    BAvatar,
    BBadge,
    BButton,
     BCardCode,

    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
  },
 
}
</script>
 
  <style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
